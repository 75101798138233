// Header
class myHeader extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
    <header class="header">
                
        <div class="left-column menu-item">
            <a class="site-logo" href="/index.html">
                <svg xmlns="http://www.w3.org/2000/svg" width="49.981" height="45.746" viewBox="0 0 49.981 45.746">
                    <path id="Path_1" data-name="Path 1" d="M0,40.217,5.46,45.746,17.078,33.988,28.695,45.746l5.463-5.529L17.078,22.929ZM27.436,22.872,44.515,40.16l5.464-5.531L38.362,22.872,49.98,11.117,44.515,5.586ZM28.695,0,17.078,11.757,5.46,0,0,5.529,17.078,22.817,34.158,5.529Z" transform="translate(0.001)" fill="#fff"/>
                </svg>   
            </a>
        </div>
        
        <div class="right-column">
            <div class="menu-item">
                <a class="contact" href="#contact">Let's talk 
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16.967 15.827">
                        <g id="Q3_icons" data-name="Q3 icons" transform="translate(25.952 -10.001) rotate(90)">
                          <path id="Path_34" data-name="Path 34" d="M25.489,16.059,18.706,9.332a1.074,1.074,0,0,0-1.583,0L10.34,16.059a1.187,1.187,0,0,0-.113,1.526,1.074,1.074,0,0,0,1.7.113l4.861-4.861V24.821a1.131,1.131,0,0,0,2.261,0V12.837L23.906,17.7a1.074,1.074,0,0,0,1.7-.113,1.187,1.187,0,0,0-.113-1.526Z" transform="translate(0)" fill="#fff"/>
                        </g>
                    </svg>
                </a>
            </div>
    
            <div class="light-dark-container">
    
                <button class="light-mode-btn mode-btn menu-item">
                    <svg id="Group_36" data-name="Group 36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 28 28">
                        <defs>
                          <clipPath id="clip-path">
                            <rect id="Rectangle_32" data-name="Rectangle 32" width="28" height="28" fill="none"/>
                          </clipPath>
                        </defs>
                        <g id="Group_35" data-name="Group 35" clip-path="url(#clip-path)">
                          <path id="Path_38" data-name="Path 38" d="M14,19.5A5.5,5.5,0,1,1,19.5,14,5.506,5.506,0,0,1,14,19.5m0-9A3.5,3.5,0,1,0,17.5,14,3.5,3.5,0,0,0,14,10.5" fill="#fff"/>
                          <path id="Path_39" data-name="Path 39" d="M14,6a1,1,0,0,1-1-1V1a1,1,0,0,1,2,0V5a1,1,0,0,1-1,1" fill="#fff"/>
                          <path id="Path_40" data-name="Path 40" d="M14,28a1,1,0,0,1-1-1V23a1,1,0,0,1,2,0v4a1,1,0,0,1-1,1" fill="#fff"/>
                          <path id="Path_41" data-name="Path 41" d="M27,15H23a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2" fill="#fff"/>
                          <path id="Path_42" data-name="Path 42" d="M5,15H1a1,1,0,0,1,0-2H5a1,1,0,0,1,0,2" fill="#fff"/>
                          <path id="Path_43" data-name="Path 43" d="M20.5,8.5a1,1,0,0,1-.818-1.574l2.3-3.276A1,1,0,0,1,23.613,4.8l-2.3,3.276a1,1,0,0,1-.82.426" fill="#fff"/>
                          <path id="Path_44" data-name="Path 44" d="M22.8,24.776a1,1,0,0,1-.82-.426l-2.3-3.276a1,1,0,1,1,1.638-1.149l2.3,3.277a1,1,0,0,1-.818,1.574" fill="#fff"/>
                          <path id="Path_45" data-name="Path 45" d="M7.5,8.5a1,1,0,0,1-.82-.426L4.385,4.8A1,1,0,1,1,6.023,3.65l2.3,3.276A1,1,0,0,1,7.5,8.5" fill="#fff"/>
                          <path id="Path_46" data-name="Path 46" d="M5.2,24.777A1,1,0,0,1,4.387,23.2l2.3-3.277a1,1,0,1,1,1.638,1.149l-2.3,3.276a1,1,0,0,1-.82.426" fill="#fff"/>
                        </g>
                    </svg>
                </button>
    
                <button class="dark-mode-btn mode-btn menu-item">
                    <img src="/imgs/hussein-khafaji-web-developer-dark-icon.svg" alt="">
                </button>
                
            </div>
        
    
            <div class="menu-item open-close-modal-btn" data-modal-target="#modal">
                <a class="hamburger-icon">
                    <span class="bar-1"></span>
                    <span class="bar-2"></span>
                </a>    
            </div>
    
        </div>
        
    </header>
        `
    }
}
customElements.define('my-header', myHeader)


class myFooter extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `

        <section class="about-me-container" id="modal">

        <div class="modal">
            <div>
                <p><span>About me</span></p>
                <h1><span>Hussein Khafaji</span></h1>
                <h5 class="me-text-container">
                Hello World! I am a creative designer and developer, who aims to work with small and mid businesses
                 to bring their passions to life. I offer both design and development services for web applications and websites!
                <br><br>
                My first love will always be working on unique and challengeable projects. Through time I was able to leverage my design
                skills through the web development toolkit, creating modern and user-friendly content that fits the needs of my clients.
                <br><br>
                I like to keep myself updated with the latest design and development techniques and to learn new skills.
                I’ve worked with modern HTML, CSS 3, Vanilla JS, React JS, gsap, Sass, WordPress PHP, and others.
                I’ve also a solid experience with design tools such as Adobe XD, Figma, Illustrator, Photoshop, Indesign and After Effects.
                </h5><br>
            </div>

            <div>

                <div class="technologies-gallery">
                    <img src="/imgs/project page imgs/Tools /adobe-xd.svg" alt="">
                    <img src="/imgs/project page imgs/Tools /adobe-illustrator.svg">
                    <img src="/imgs/project page imgs/Tools /adobe-illustrator.svg">
                    <img src="/imgs/project page imgs/Tools /adobe-photoshop.svg">
                    <img src="/imgs/project page imgs/Tools /figma.svg">
                    <img src="/imgs/project page imgs/Tools /VScode.svg">
                    <img src="/imgs/project page imgs/Tools /wordpress.svg">
                    <img src="/imgs/project page imgs/Tools /html-5.svg">
                    <img src="/imgs/project page imgs/Tools /css3.svg">
                    <img src="/imgs/project page imgs/Tools /javascript.svg">
                    <img src="/imgs/project page imgs/Tools /sass.svg">
                    <img src="/imgs/project page imgs/Tools /shopify.svg">
                    <img src="/imgs/project page imgs/Tools /woocommerce.svg">
                    <img src="/imgs/project page imgs/Tools /php-logo.png">
                </div>
            </div>
        </div>

    </section>

<footer class="footer">
  <div class="contact" id="contact">
      <h1>Let’s talk!</h1>
      <span>
          <h2>Hussein Khafaji</h2>
          <a href="tel:+35846643663">+358 4066 43 663</a>
          <a href="mailto:hi@husseinkhafaji.com">hi@husseinkhafaji.com</a>
      </span>
  </div>

  <div class="copyright">
      <p>© Hussein Khafaji - <script>document.write(/\d{4}/.exec(Date())[0])</script></p>
      <span>
          <a href="">Ig</a>
          <a href="">In</a>
          <a href="">Be</a>
      </span>
  </div>
</footer>
        `
    }
}
customElements.define('my-footer', myFooter)





// Modals
const openModalBtns = document.querySelectorAll('[data-modal-target]')
const closeModalBtns = document.querySelectorAll('[data-close-btn]')
const modalBtn = document.querySelector('.open-close-modal-btn')


openModalBtns.forEach(button => {
    button.addEventListener('click', () =>{
        const modal = document.querySelector(button.dataset.modalTarget)
        openModal(modal)
    })
})



function openModal(modal) {
    if (modal == null) return
    modal.classList.toggle('show')
    modalBtn.classList.toggle('show')
}




// gsap Animation
gsap.registerPlugin(ScrollTrigger)

gsap.from('.about-me-container *', { duration: 1, y: '100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .3 })
gsap.from('h1 span', { duration: 1, y: '100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .3 })
gsap.from('.project-info-column p', { duration: 1, y: '100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .2 })
gsap.from('.project-info-row', { duration: 1, y: '100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .2 })
gsap.from('.project-info-column li', { duration: 1, y: '100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .2 })
gsap.from('.project-gallery img', { duration: 1, y: '10%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .2 })
gsap.from('.menu-item', { duration: 1, y: '-100%', ease: "power1.inOut", opacity: 0, delay: .5, stagger: .2  })
gsap.from('button', { duration: 1, y: '200%', ease: "power1.inOut", opacity: 0, delay: .5  })
gsap.from('.project', {
    duration: 1,
    y: '15%',
    opacity: 0,
    ease: "power1.inOut",
    delay: 1.1,
    stagger: .1
})



// Light & Dark modes
let lightMode = localStorage.getItem('lightMode')

const darkModeBtn = document.querySelector('.dark-mode-btn')
const lightModeBtn = document.querySelector('.light-mode-btn')

const enableLightMode = () => {
    document.body.classList.add('light-mode')
    localStorage.setItem('lightMode', 'enabled')
    lightModeBtn.classList.add('active')
    darkModeBtn.classList.add('hide')
}


const disableLightMode = () => {
    document.body.classList.remove('light-mode')
    localStorage.setItem('lightMode', null)
    lightModeBtn.classList.remove('active')
    darkModeBtn.classList.remove('hide')
}


if (lightMode === 'enabled') {
    enableLightMode()
}

darkModeBtn.addEventListener('click', () => {
    lightMode = localStorage.getItem('lightMode')

    if(lightMode !== 'enabled'){
        enableLightMode()
    } else {
        disableLightMode()
    }
})


lightModeBtn.addEventListener('click', () => {
    lightMode = localStorage.getItem('lightMode')

    if(lightMode !== 'disabled'){
        disableLightMode()
    } else {
        enableLightMode()
    }
})








